@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
	color: #000;
	letter-spacing: 0.01em;
}

.center-align {
	text-align: center;
}

.white-bg {
	background: white;
}

.primary-bg {
	background: #b05ffd;
}

.proposition-list {
	padding-top: 1rem;
}

.h1-hero {
	color: #fff;
	font-size: 48px;
	text-align: center;
	font-weight: bold;

	@media screen and (max-width: 768px) {
		font-size: 40px;
	}

	@media screen and (max-width: 480px) {
		font-size: 32px;
	}
}

.hero-text {
	color: #fff;
	font-size: 24px;
	text-align: center;
}

.img-wrap {
	height: 100%;
	max-width: 700px;
	margin: auto;
}

.fit-img {
	width: 100%;
}

.top-navbar {
	background: #d7d7d7;
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 45px;
	align-items: center;
	padding-left: 2rem;
	justify-content: space-between;
}
.contacts-wrapper {
	display: flex;
	align-items: center;
}
.contacts-wrapper p {
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: 0.01em;
	color: #000000;
	padding-left: 0.8rem;
}

.social-media-wrapper {
	padding-right: 2rem;
}

.phone-wrapper {
	display: flex;
	padding-left: 2rem;
}

.expand-row {
	width: 100%;
}

.white-text {
	color: white;
}

.black-text {
	color: black;
}

.gray-text {
	color: gray;
}

.pl {
	padding-left: 20px;
}

.pr {
	padding-right: 20px;
}
.font-white {
	color: #fff;
}

.logo-text {
	font-size: 14px;
	font-weight: 400;
	opacity: 0.6;
	position: relative;
	padding-left: 1.2rem;
}

.logo-wrapper {
	padding-left: 2rem;
}

.footer-contacts-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-flow: column;
	padding-right: 3rem;
	padding-top: 6rem;
}
.footer-items-wrapper {
	display: flex;
}
.email-wrapper {
	display: flex;
}

.copy-right-wrapper {
	padding-left: 2.6rem;
	margin-top: 2rem;
}
.copy-right-wrapper p {
	color: #fdfdfd;
	opacity: 0.4;
	font-size: 14px;
}

.pl-1 {
	padding-left: 1rem;
}

.footer-contacts-wrapper .social-media-wrapper {
	padding-right: 2rem;
	margin-top: 1rem;
	display: flex;
	padding-top: 0.5rem;
}

.footer-contacts-wrapper .phone-wrapper {
	margin-top: 1.5rem;
}
.info-text {
	font-size: 16px;
	line-height: 28px;
	color: #000000;
}

.makeStyles-root-7 {
	padding: 80px;
}

.MuiAccordionSummary-content {
	display: unset !important;
}
.MuiAccordionSummary-content p {
	font-weight: bold;
	font-size: 24px !important;
	line-height: 33px !important;
	color:#01768a;
}

.MuiTypography-body1 {
	font-family: 'Open Sans', sans-serif !important;
	font-size: 16px !important;
	line-height: 28px !important;
}

.MuiAccordion-rounded {
	background: #ffffff;
	border: 2px solid #fec10e;
	box-sizing: border-box;
	border-radius: 24px !important;
	min-height: 96px !important;
	margin-bottom: 12px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}
.our-propositions-wrapper {
	padding: 40px 80px;
}

.makeStyles-heading-2,
.makeStyles-secondaryHeading-3 {
	font-size: 24px !important;
}

.our-propositions-heding .proposition-heading {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 36px !important;
	color: #01768a;
}
.reach-us-heading {
	display: flex;
	justify-content: center;
	font-weight: bold !important;
	font-size: 36px !important;
	color: #01768a;
	margin-bottom: 2rem !important;
}

.video-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 60px;
}

.video-wrapper .our-propositions-heding {
	margin-bottom: 2rem;
}

.video-wrapper iframe {
	border-radius: 12px;
}

.our-growth-text {
	font-weight: bold;
	font-size: 36px;
	line-height: 49px;
	color: #01768a;
}

.our-growth-paragraph {
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
}

.growth-led-solutions-list-wrapper li {
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
}

.our-growth-image {
	padding-top: 7rem;
}

.our-growth-hint-wrapper p {
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
	padding-top: 1rem;
	padding-right: 16rem;
}
.our-customer-reservoir {
	height: auto;
	background-color: #ffffff;
	border: 1px solid #fec10e;
	box-sizing: border-box;
	border-radius: 24px;
}
.our-customer-reservoir-inner-wrapper {
	padding: 40px 0 3rem 0;
}
.sub-heading {
	font-weight: 600;
	font-size: 16px;
}

.our-customer-reservoir {
	margin-top: 80px !important;
}

.our-customers-paragraph {
	margin-bottom: 2.5rem;
}
.not-onboard {
	padding-top: 0 !important;
}

.reach-us-wrapper {
	background-color: #f3f3f3;
	border-radius: 24px;
	margin-bottom: 4rem;
}
.enquiry-form {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
}

.enquiry-text {
	font-weight: bold;
	font-size: 32px;
	line-height: 44px;
	color: #334b4f;
}

.enquiry-info {
	font-weight: normal;
	font-size: 22px;
	line-height: 30px;
	color: #66787b;
	padding-left: 1rem;
	position: relative;
	top: 0.5rem;
}

.reach-us-parent-wrapper {
	padding: 7rem 80px 0 80px;
}

.on-board {
	padding-top: 7rem;
}

.location-text-wrapper {
	font-size: 18px;
	line-height: 32px;
	color: #001e23;
}
.contact-us-location-wrapper {
	padding-top: 11rem;
}
.pr-1 {
	margin-right: 20px;
}
.mt-4 {
	margin-top: 0.4rem;
}
.seperator {
	position: relative;
}
.seperator::after {
	content: '';
	background-color: #001e23;
	width: 1px;
	position: absolute;
	border: 1px solid;
	height: 40vh;
	top: 10.6rem;
	left: 5rem;
}

.active {
	color: #01768a;
	font-weight: 600;
}

a:hover {
	color: #01768a !important;
	text-shadow: 2px, 2px, 5px #01768a;
}
.sumbit-button:hover {
	color: #fff !important;
}

.expert-button:hover {
	color: #fff !important;
}

.footer-link:hover {
	color: #fff !important;
}
.our-propositions-parent-wrapper {
	padding-top: 7rem;
}
@media screen and (max-width: 499px) {
	.our-propositions-parent-wrapper {
		padding-top: 2rem;
	}
	.social-media-wrapper {
		display: none;
	}
	.top-navbar {
		padding-left: 1rem;
	}
	.phone-wrapper {
		padding-left: 1rem;
	}

	.our-propositions-wrapper {
		padding: 20px;
	}
	.our-propositions-heding .proposition-heading {
		font-size: 24px !important;
	}
	#solutions,
    #onboard,
    #services {
        display: none;
    }
	.reach-us-parent-wrapper {
		padding: 0 20px;
	}
	.seperator {
		display: none;
	}
	.contact-us-location-wrapper {
		padding-top: 1rem;
		padding-left: 1rem;
	}
	.row > * {
		padding-right: 0 !important;
	}
	.enquiry-text {
		font-size: 24px;
		margin-bottom: 1rem !important;
	}
	.enquiry-info {
		display: none;
	}
	.video-wrapper {
		display: none;
	}
}

@media (min-width: 499px) and (max-width: 768px) {
	.our-growth-hint-wrapper p {
		padding-right: 0;
		padding-top: 2rem;
	}
	.our-customer-reservoir {
		height: auto;
	}
	.seperator {
		display: none;
	}
	.contact-us-location-wrapper {
		padding-top: 2rem;
		padding-left: 2rem;
		padding-bottom: 1rem;
	}

	.our-propositions-wrapper {
		padding: 40px;
	}
	.reach-us-parent-wrapper {
		padding: 0 40px;
	}
	.video-wrapper {
		display: none;
	}
	#services {
        display: none;
    }
}

.MuiAccordion-root:before {
	opacity: 0 !important;
}
.proposition-font {
	font-weight: 600;
}
.MuiInput-underline:before {
	border-bottom: none !important;
}

.makeStyles-formControl-4 {
	margin: unset !important;
	min-width: unset !important;
	width: 100%;
}
.makeStyles-selectEmpty-5 {
	margin-top: 0 !important;
}

.MuiInput-formControl {
	width: 100%;
	padding: 16px 24px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 10px;
	border: 0;
}

.MuiInputBase-root {
	margin: unset !important;
	display: flex !important;
	font-family: 'Open Sans', sans-serif !important;
}
.MuiFormControl-root {
	width: 100%;
	margin: unset !important;
}
.MuiSelect-select.MuiSelect-select {
	font-size: 18px;
	opacity: 0.6;
	padding: 0.1rem !important;
}
.MuiInput-formControl {
	padding: 16px 0 16px 24px;
}

.form-hint {
	font-size: 12px;
	color: #ff0000;
	margin-top: 1rem;
}

.view-more-link {
    background-color: #ccc;
    color: #000;
    padding: 0.5rem 0.7rem;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
}

.view-more-div {
    position: fixed;
    bottom: 1.5rem;
}

.MuiPaper-elevation1 {
	box-shadow: unset !important;
}

ul.list-wrapper {
	list-style: none;
}

ul.list-wrapper li {
	position: relative;
}

ul.list-wrapper li:before {
	content: '✓';
	left: -1.4rem;
	position: absolute;
}
